/* eslint-disable */
import {lista, processar, pegaLista, pegaDados, nao_autorizado} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {BannerSCH, getBaseSCH} from "../../search/BannerSCH"
import {EventBus} from '../../helpers/event-bus'
import Banner from '../../models/banner'

const url = '/banner';

const state = {
  all: [],
  banner: new Banner(),
  filtro: new Filtro()
};
const getters = {
  listaBanners: (state) => {
    return state.all !== undefined ? state.all : []
  },
  pegaBanner: (state) => {
    return state.banner
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};


const actions = {
  setBanner({commit}, banner) {
    commit('SET_BANNER', banner)
  },
  // searchItem({commit}, search) {
  //   state.filtro.filters.addFilter('descricao' ,
  //     "%"+search+"%",
  //     'like'
  //   )
  // },
  async getItem({commit}, id) {
    var filtro = getBaseSCH('banner_id', id, '=');
    const list = (await pegaLista(url, filtro)).data;
    commit('SET_BANNERS', list.data[0]);
    EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', BannerSCH(perPage))
  },

  async setAllBanners({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_BANNERS', list);
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
  async processarBanner({commit}, {banner,acao,onSucesso, onErro}) {
    try {
      let res = await processar(url + (acao === 'remover' ? '/delete' : '/commit') , banner, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data);
    }
  },

};

const mutations = {
  SET_BANNERS(state, banners) {
    state.all = banners
  },
  SET_BANNER(state, banner) {
    state.banner = banner
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

