/* eslint-disable */
import {pegaDados, pegaLista, processar} from '../../services/GenericAPI'
import {Filtro, Where} from "../../models/filtro/filtro";
import {ConsultoriaSCH} from "../../search/ConsultoriaSCH"
import {EventBus} from '../../helpers/event-bus'
import Consultoria from '../../models/consultoria'

const url = '/consultoria';

const state = {
  all: [],
  consultoria: new Consultoria(),
  filtro: new Filtro()
};
const getters = {
  listaConsultorias: (state) => {
    return state.all !== undefined ? state.all : []
  },
  pegaConsultoria: (state) => {
    return state.consultoria
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};


const actions = {
  setConsultoria({commit}, consultoria) {
    commit('SET_CONSULTORIA', consultoria)
  },
  // searchItem({commit}, search) {
  //   state.filtro.filters.addFilter('descricao' ,
  //     "%"+search+"%",
  //     'like'
  //   )
  // },
  async getItem({commit}, id) {
    var filtro = getBaseSCH('consultoria_id', id, '=');
    const list = (await pegaLista(url, filtro)).data;
    commit('SET_CONSULTORIAS', list.data[0]);
    EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', ConsultoriaSCH(perPage))
  },

  async setAllConsultorias({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_CONSULTORIAS', list);
      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      // this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }

  },
  async processarConsultoria({commit}, {consultoria,acao,onSucesso, onErro}) {
    try {
      let res = await processar(url + (acao === 'remover' ? '/delete' : '/commit') , consultoria, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data);
    }
  },

};

const mutations = {
  SET_CONSULTORIAS(state, consultorias) {
    state.all = consultorias
  },
  SET_CONSULTORIA(state, consultoria) {
    state.consultoria = consultoria
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

