<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="app-login">
    <v-container class="grey lighten-5" style="margin-top: 10%; background: transparent!important;">
          <v-card class="pa-2">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <div style="height: 200px">
                    <v-img :src="logo_cor" alt=""></v-img>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <div class="formLogin">
                    <v-form >
                      <v-container>
                        <v-row justify-center>

                          <v-col cols="12">
                            <h1 style="font-weight: 400">Login</h1>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field v-model="email" hide-details variant="outlined"
                                          density="compact" name="usuario" label="Usuário"
                                          :color="setRequerido(email)"></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field v-model="senha" variant="outlined" density="compact"
                                          name="senha" label="Senha"
                                          :type="show1 ? 'text' : 'password'" hide-details
                                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                          @click:append="show1 = !show1" :color="setRequerido(senha)"></v-text-field>

                          </v-col>
                          <v-col cols="12">
                            <div style="text-align: center;">
                              <v-btn  class="_btn" @click="login()" :loading="loading">
                                <!--<v-progress-circular v-if="loading" :size="20" :width="2" indeterminate color="white"-->
                                                     <!--style="margin-right: 5%"></v-progress-circular>-->
                                Entrar</v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
    </v-container>
    <!-- Dialog que vai exibir a lista de erros caso aja uma validação -->
    <v-dialog v-model="dialogError" width="50%">
      <v-card v-if="objMsgError !== null">
        <v-card-title class="headline">{{ objMsgError.message }}</v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div v-if="objMsgError.details !== null">
            <v-container>
              <ul :key="index" v-for="(message, index) in objMsgError.details">
                <li> {{ message.mensagem }}</li>
              </ul>
            </v-container>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="toggleDialogError()">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Popup que vai exibir mensagens de sucesso -->
    <v-snackbar v-model="showSnack" top color="success" :timeout="2000">
      {{ msgSuccess }}
      <template v-slot:actions>
        <v-icon @click="toggleSnackSuccess">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */
  import {mapActions, mapGetters, mapState} from "vuex";
  import miscMixin from '../helpers/misc'
  import router from '../router'
  import logo_cor from '../assets/img/logo_cor.svg'
  export default {
    name: "Login",
    mixins: [miscMixin],
    // extends: Bar,
    data: () => ({
      logo_cor,
      email: '',
      senha: '',
      show1: false,
      loading: false,

    }),
    methods: {
      ...mapActions('geral', [, 'toggleDialogError', 'toggleSnackSuccess', 'setTab']),
      ...mapActions('usuario', ['autenticarUsuario']),
      login() {
        this.loading = true
        var usuario = {
          email: this.email,
          senha: this.senha,
        }
        this.autenticarUsuario({
          usuario: usuario,
          onSucesso: () => {
            this.loading = false
            router.push({path: '/admin/principal'}).catch(err => {
            })
          },
          onErro: () => {
            this.loading = false
          }
        })
      }
    },
    computed: {
      ...mapState('geral', {
        dialogError: 'dialogError',
        objMsgError: 'objMsgError',
        snackSuccess: 'snackSuccess',
        msgSuccess: 'msgSuccess'
      }),
      showSnack: {
        get() {
          return this.snackSuccess
        },
        set() {
          this.toggleSnackSuccess()
        }
      }
    },
    created() {
    },
    mounted() {
    },
    watch: {}
  }
</script>

<style scoped>
</style>
