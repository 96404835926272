<template>
  <div class="decimalDisabled" :style="''+css">
    {{prefix}} {{ valueWithMaskMoney() }} {{sufix}}
  </div>
</template>

<script>

import {mascaraValor} from '@/components/pComponents/commons/misc'

export default {
  name: 'pLabel',
  props: {
    css: {
      default: 'color: black'
    },
    sufix: {
      default: ''
    },
    prefix: {
      default: ''
    },
    name: {
      type: String
    },
    value: {
      default: 0
    }
  },
  methods: {
    valueWithMaskMoney() {
      return mascaraValor(this.value)
    }
  }
}
</script>

<style type="text/css">

</style>
