/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export default class Administrador {

  constructor () {
    this.administrador_id = uuidV4();
    this.cnpj_cpf = ''
    this.nome = ''
    this.logrado = ''
    this.complemento = ''
    this.bairro = ''
    this.cidade = ''
    this.fone = ''
    this.email = ''
    this.senha = ''
    this.senha_scan = ''
    this.sync_at = ''
  }
}