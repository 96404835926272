/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export default class Cartela {

  constructor () {
    this.cartela_id = uuidV4();
    this.titulo = ''
    this.texto = ''
    this.ordem = ''
    this.imagens= [];
    this.urls_imagem= [];
    this.cartelas_videos= [];
  }
}
