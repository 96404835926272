/* eslint-disable */
import {processar, pegaDados} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {AdministradorSCH, getBaseSCH} from "../../search/AdministradorSCH"
import Administrador from '../../models/administrador';

const url = '/administrador';

const state = {
  all: [],
  administrador: new Administrador(),
  filtro: new Filtro()
};
const getters = {
  listaAdministradores: (state) => {
    return state.all !== undefined ? state.all : []
  },
  pegaAdministrador: (state) => (id) => {
    let admin = state.all.data.find(administrador => administrador.administrador_id === id)
    return admin !== null ? admin : new Administrador()
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};


const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.addSearch(['cnpj_cpf', 'nome', 'email', 'cidade'],
       '%'+search+'%',
        'ilike'
      )
  },

  setAdministrador({commit}, administrador) {
    commit('SET_EVENTO', administrador)
  },
  async getItem({commit}, {id, onSucesso, onErro}) {
    try {
      var filtro = getBaseSCH('administrador_id', id, '=');
      const list = (await pegaDados(url + '/get', filtro)).data;
      commit('SET_EVENTO', list.data[0]);

      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', AdministradorSCH(perPage))
  },

  async setAllAdministradores({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_EVENTOS', list);
      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      // this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }

  },
  async processarAdministrador({commit}, {administrador, acao, onSucesso, onErro}) {
    try {
      // console.log('Processar: ', administrador);
      let res = await processar(url + (acao === 'remover' ? '/delete' : '/commit') , administrador, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data);
    }
  },

};

const mutations = {
  SET_EVENTOS(state, administradores) {
    state.all = administradores
  },
  SET_EVENTO(state, administrador) {
    state.administrador = administrador
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

