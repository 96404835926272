<template>
  <div>
    <v-currency-field :name=name
                      dense
                      outlined
                      :label=label
                      :prefix=buildPrefix
                      :suffix=buildSuffix
                      :value="buildValue"
                      @input="updateValue"
                      :class=classes
                      :decimal-length=buildDecimals
                      :readonly=chkReadonly
                      :rules=rulesValidation
                      :hint=hint
                      :disabled=disabled
                      :maxlength=maxlength
                      :min=min
                      :max=buildMax
                      :hide-details="hideDetails"
                      :allow-negative=false>
    </v-currency-field>
  </div>
</template>

<script>
/* eslint-disable */
  import validator from './commons/validator'
  import {mapGetters} from 'vuex'

export default {
  mixins: [validator],
  name: 'pCurrency',
  props: {
    name: {
      type: String
    },
    label: {
      default: ''
    },
    validation: {
      type: String,
      default: ''
    },
    addclass: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
    },
    hint: {
      type: String,
      default: ''
    },
    percent: {
      type: Boolean,
      default: false
    },
    min: {
      type: String | Number,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    normal: {
      type: Boolean,
      default: false
    },
    decimals: {
      type: Number,
      default: 2
    },
    maxlength: {
      type: String | Number,
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    rulesValidation: [],
    pInputType: null,
    classes: ''
  }),
  methods: {
    updateValue(valor) {
      this.$emit('input', valor)
    }
  },
  computed: {
    buildPrefix() {
      if (this.normal || this.percent) {
        return ''
      }
      return 'R$'
    },
    buildSuffix() {
      if (this.percent) {
        return '%'
      }
      return ''
    },
    buildDecimals() {
      return Number(this.decimals)
    },
    buildMin() {
      return 0
    },
    buildMax() {
      if (this.percent) {
        return 100
      }
      return 9999999.99
    },
    buildValue() {
      return Number(this.value)
    },
    chkReadonly() {
      return this.disableField || this.readonly
    }
  },
  created() {
    /* eslint-disable no-console */
    this.classes = this.addclass
    this.classes += " v_decimal"

    if (this.validation !== '') {
      this.buildRules(this)
    }

    if (this.classes.indexOf('requerido2') === -1) {
      this.classes += ' padrao'
    }
    /* eslint-enable no-console */
  }
}
</script>

<style type="text/css">
  .v_decimal.v-input input {
    text-align: right;
  }
</style>
