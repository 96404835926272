/* eslint-disable */
import {processar, pegaDados, lista} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {SorteioSCH, getBaseSCH} from "../../search/SorteioSCH"
import Sorteio from '../../models/sorteio';
import FaixaVendaOnline from '../../models/faixa_venda_online';
import { v4 as uuidV4 } from 'uuid';

const url = '/sorteio';

const state = {
  all: [],
  sorteio: new Sorteio(),
  allList: [],
  filtro: new Filtro()
};
const getters = {
  listaSorteios: (state) => {
    return state.all !== undefined ? state.all : []
  },
  listaAllSorteios: (state) => {
    return state.allList
  },
  pegaSorteio: (state) => {
    return state.sorteio
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};

const actions = {
  setSorteio({commit}, sorteio) {
    commit('SET_SORTEIO', sorteio)
  },
  async getItem({commit}, {id, onSucesso, onErro}) {
    try {
      var filtro = getBaseSCH('sorteio_id', id, '=');
      const list = (await pegaDados(url + '/get', filtro)).data;
      commit('SET_GET_ITEM', list.data[0]);

      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', SorteioSCH(perPage))
  },

  async setAllListSorteios({commit},{onSucesso, onErro}) {
    try {
      const list = (await lista(url+'/getAll')).data
      // const list = (await pegaLista(url+'/all', {administrador_id: sessionStorage.getItem('administrador_id')})).data
      // console.log('SET_ALL_SORTEIOS: ', list)
      commit('SET_ALL_SORTEIOS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  async setAllSorteios({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_LIST', list);
      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      onErro()
    }

  },
  async processarSorteio({commit}, {sorteio, acao, onSucesso, onErro}) {
    try {
      console.log(sorteio);
      let res = await processar(url + (acao === 'remover' ? '/delete' : '/commit') , sorteio, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data);
    }
  },
  async processarOpcao({commit}, {sorteio, path, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url + '/' + path, sorteio, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      console.log('Caiu no Catch')
      console.log(error)
      onErro()
    }
  },
};

const mutations = {
  SET_LIST(state, sorteios) {
    state.all = sorteios
  },
  SET_ALL_SORTEIOS(state, sorteios) {
    state.allList = sorteios
  },
  SET_GET_ITEM(state, sorteio) {
    if(sorteio.faixa_venda_online.length > 0) {
      sorteio.faixa_venda_online = new FaixaVendaOnline(
        sorteio.faixa_venda_online[0].faixa_id,
        sorteio.faixa_venda_online[0].sorteio_id,
        sorteio.faixa_venda_online[0].numero_inicial,
        sorteio.faixa_venda_online[0].numero_final
      )
    } else {
      sorteio.faixa_venda_online = new FaixaVendaOnline(uuidV4(), sorteio.sorteio_id)
    }
    state.sorteio = sorteio
  },
  SET_SORTEIO(state, sorteio) {
    state.sorteio = sorteio
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
