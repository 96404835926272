<template>
    <pro-modal-cadastro :modal="renovarLoginModal" width="30%" titulo="Renovar usuário">
        <pro-alerta :texto="info" :cor="cor" :show="mostrar" @abriAlerta="fecharAlerta"></pro-alerta>
        <v-container form_id="renovar_login">
          <v-row>
            <v-col cols="12">
              <v-text-field name="usuario" dense outlined class="inp" v-model="user.email" label="Usuário"
                            id="usuario" prepend-inner-icon="mdi-account"
                            v-on:keyup="PressEnter(user.senha, user.email)" required></v-text-field>

              <v-text-field name="senha" dense outlined class="inp" v-model="user.senha" label="Senha"
                            prepend-inner-icon="mdi-lock" type="password" id="senha"
                            v-on:keyup="PressEnter(user.senha, user.email)" required></v-text-field>
              <div style="text-align: right;">
                <v-btn text @click="fechaModal()" color="gray" style="margin-right: 14px;" :disabled="pegaTimeCount<=0"> Cancelar</v-btn>
                <v-btn @click="login()" color="primary" :loading="spinner2">Entrar</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
    </pro-modal-cadastro>
</template>

<script>
  /* eslint-disable */
  import {EventBus} from '../helpers/event-bus'
  import miscMixin from "../helpers/misc"
  // import miscLogin from "./misc_login";
  import {mapActions, mapGetters, mapState} from "vuex"

  export default {
  mixins: [miscMixin],
  data: () => ({
    info: '',
    cor: '',
    mostrar: false,
    intervalo: null,
    spinner2: false,
    user: [],
  }),

  methods: {
    ...mapActions('usuario', ['autenticarUsuario']),
    ...mapActions('geral', ['setModal', 'toggleDialogError', 'toggleSnackSuccess', 'setRenovarLogin', 'setShowRenovarLoginMsg', 'setReloadPageRenovarLogin']),
    ...mapActions('token', ['setarToken']),
    login() {
      this.loading = true

      var usuario = {
        email: this.user.email,
        senha: this.user.senha,
      }
      this.autenticarUsuario({
        usuario: usuario,
        onSucesso: () => {
          this.loading = false
        },
        onErro: () => {
          this.loading = false
        }
      })
    },
    PressEnter(senha, usuario) {
      let _this = this;
      let user = document.getElementById('usuario');
      let senhaId = document.getElementById('senha');

      user.addEventListener('keypress', function (e) {
        if (e.keyCode === 13 || e.which === 13) {
          if (!_this.validarCampo(usuario)) {
            user.focus()
          } else if (!_this.validarCampo(senha)) {
            senhaId.focus()
          } else {
            _this.login()
          }
        }
      }, false);

      senhaId.addEventListener('keypress', function (e) {
        if (e.keyCode === 13 || e.which === 13) {
          if (!_this.validarCampo(senha)) {
            senhaId.focus()
          } else {
            if (!_this.validarCampo(usuario)) {
              user.focus()
            } else {
              _this.login()
            }
          }
        }
      }, false)
    },
    fechaModal() {
        this.setShowRenovarLoginMsg(true);
        this.setReloadPageRenovarLogin(true);
        this.setRenovarLogin(false)
    },

    alerta(text, cor, show) {
      let _this = this;
      this.info = text;
      this.cor = cor;
      this.mostrar = show;
      this.intervalo = setTimeout(function() {
        _this.mostrar = false
      }, 3500)
    },
    fecharAlerta(fechar) {
      clearTimeout(this.intervalo);
      this.mostrar = fechar
    },
  },
  computed: {
    ...mapGetters('token', ['pegaTimeCount']),
    ...mapState('geral', {
      dialogError: 'dialogError',
      objMsgError: 'objMsgError',
      snackSuccess: 'snackSuccess',
      msgSuccess: 'msgSuccess',
      renovarLoginModal: 'renovarLoginModal',
      showMsg: 'showRenovarLoginMsg',
      reloadPage: 'reloadPageRenovarLogin'
    }),
  },
  mounted() {
    EventBus.$on('USUARIO_AUTENTICADO', (sucesso, usuario, existe) => {
      this.spinner2 = false;
      if (sucesso){
        if(this.reloadPage) {
          document.location.reload(true)
        } else {
          this.setarToken(sessionStorage.getItem('token'), sessionStorage.getItem('funcionario_id'))
        }
        this.user.username = '';
        this.user.senha = '';
        this.fechaModal()
      }
    })

  },
  watch: {
    'renovarLoginModal': function() {
      if(this.showMsg && this.renovarLoginModal === true){
        this.fecharAlerta(false);
        this.alerta('Sua sessão expirou. Faça o login novamente.', 'orange', true)
      }
    }
  }
}
</script>
