/* eslint-disable */
import {lista, processar, pegaLista, pegaDados, nao_autorizado} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {EventoSCH, getBaseSCH} from "../../search/EventoSCH"
import {EventBus} from '../../helpers/event-bus'
import Evento from '../../models/evento'

const url = '/evento';

const state = {
  all: [],
  evento: new Evento(),
  filtro: new Filtro()
};
const getters = {
  listaEventos: (state) => {
    return state.all !== undefined ? state.all : []
  },
  pegaEvento: (state) => {
    return state.evento
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};


const actions = {
  setEvento({commit}, evento) {
    commit('SET_EVENTO', evento)
  },
  async getItem({commit}, {id, onSucesso, onErro}) {
    try {
      var filtro = getBaseSCH('evento_id', id, '=');
      const list = (await pegaDados(url + '/get', filtro)).data;
      commit('SET_EVENTO', list.data[0]);

      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', EventoSCH(perPage))
  },

  async setAllEventos({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_EVENTOS', list);
      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      // this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }

  },
  async processarEvento({commit}, {evento,acao,onSucesso, onErro}) {
    try {
      // console.log('Processar: ', evento);
      let res = await processar(url + (acao === 'remover' ? '/delete' : '/commit') , evento, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data);
    }
  },

};

const mutations = {
  SET_EVENTOS(state, eventos) {
    state.all = eventos
  },
  SET_EVENTO(state, evento) {
    state.evento = evento
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

