/* eslint-disable */
import {pegaDados, pegaLista, processar} from '../../services/GenericAPI'
import {Filtro, Where} from "../../models/filtro/filtro";
import {CartelaSCH, getBaseSCH} from "../../search/CartelaSCH"
import {EventBus} from '../../helpers/event-bus'
import Cartela from '../../models/cartela'

const url = '/cartela';

const state = {
  all: [],
  cartela: new Cartela(),
  filtro: new Filtro()
};
const getters = {
  listaCartelas: (state) => {
    return state.all !== undefined ? state.all : []
  },
  pegaCartela: (state) => {
    return state.cartela
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};


const actions = {
  setCartela({commit}, cartela) {
    commit('SET_CARTELA', cartela)
  },
  searchItem({commit}, search) {
    state.filtro.filters.addFilter('titulo',
      "%" + search + "%",
      'ilike'
    )
  },
  async getItem({commit}, {id, onSucesso, onErro}) {
    try {
      var filtro = getBaseSCH('cartela_id', id, '=');
      const list = (await pegaDados(url + '/get', filtro)).data;
      commit('SET_CARTELA', list.data[0]);
      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', CartelaSCH(perPage))
  },

  async setAllCartelas({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data;
      commit('SET_CARTELAS', list);
      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      // this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }

  },
  async processarCartela({commit}, {cartela, acao, onSucesso, onErro}) {
    try {
      console.log('processarCartela:',cartela);
      let res = await processar(url + (acao === 'remover' ? '/delete' : '/commit'), cartela, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data);
    }
  },

};

const mutations = {
  SET_CARTELAS(state, cartelas) {
    state.all = cartelas
  },
  SET_CARTELA(state, cartela) {
    state.cartela = cartela
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

