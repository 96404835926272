import { render, staticRenderFns } from "./CropperImagem.vue?vue&type=template&id=bf108824&scoped=true"
import script from "./CropperImagem.vue?vue&type=script&lang=js"
export * from "./CropperImagem.vue?vue&type=script&lang=js"
import style0 from "./CropperImagem.vue?vue&type=style&index=0&id=bf108824&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf108824",
  null
  
)

export default component.exports