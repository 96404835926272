/* eslint-disable */
import router from '../router'
import {API,setToken} from '../api'
import {EventBus} from '../helpers/event-bus'
import {acaoApi} from '../helpers/enums'

export const lista = (url) => {
  // return API.get(url)
  setToken(sessionStorage.getItem('token'), sessionStorage.getItem('funcionario_id'));
  let res = API.get(url);
  res.catch(
    (erro) => isTokenExpired(erro.response)
  );
  return res
};

export const processar = (url, objeto, acao) => {
  // setToken(sessionStorage.getItem('token'))
  // if (acao === acaoApi.GRAVAR) {
  //   var res = API.post(url, objeto)
  // } else if (acao === acaoApi.REMOVER) {
  //   var res = API.delete(url, {data: objeto})
  // }
  //
  // res.catch(
  //   (erro) => {
  //     if(erro.response.status === 401){
  //       var renovar = (erro.response.data.message === "Token has expired")
  //       EventBus.$emit('RENOVAR_LOGIN', renovar)
  //     }
  //   }
  // )
  // return res
  setToken(sessionStorage.getItem('token'), sessionStorage.getItem('funcionario_id'));
  var res
  if (acao === acaoApi.REMOVER) {
    res = API.delete(url, {data: objeto})
  } else {
    res = API.post(url, objeto)
  }
  res.catch(
    (erro) => isTokenExpired(erro.response)
  );
  return res

};
export const pegaLista = (url, data) => {
  // setToken(sessionStorage.getItem('token'))
  // return API.get(url, {params: {data: data}})
  let res = API.get(url, {params: {data: data}});
  res.catch(
    (erro) => isTokenExpired(erro.response)
  );
  return res

};
export const pega = (url, id) => {
  // setToken(sessionStorage.getItem('token'))
  // return API.get(url + '/' + id)
  let res = API.get(url + '/' + id);
  res.catch(
    (erro) => isTokenExpired(erro.response)
  );
  return res
};
export const autentica = (url, objeto) => {
  // setToken(sessionStorage.getItem('token'))
  // return API.post(url, objeto)
  let res = API.post(url, objeto);
  res.catch(
    (erro) => isTokenExpired(erro.response)
  );
  return res

};

export const pegaDados = (url, data) => {
  setToken(sessionStorage.getItem('token'))
  var res = API.post(url, data)
  return res
};


const isTokenExpired = (response) => {
  if(response.status === 401){
    let renovar = (response.data.message === "Token has expired");
    EventBus.$emit('RENOVAR_LOGIN', renovar)
  }
};

export const setarToken = (token, userID) => {
  setToken(token, userID)
};


export const nao_autorizado = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      sessionStorage.clear()
      router.push({path: '/'}).catch(err => {
      })
    }
  }
}


