/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Dash from './views/Dash.vue'
import Login from './views/Login.vue'

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Login,
    },
    {
      path: '/admin',
      name: 'admin',
      component: Dash,
      meta: 'Admin',
      children: [
        // PRINCIPAL
        {
          path: 'principal',
          name: 'Principal',
          meta: 'Principal',
          component: () => import(/* webpackChunkName: "about" */ './views/Principal.vue')
        },
        {
          path: 'usuario',
          name: 'Usuarios',
          meta: 'Usuarios',
          component: () => import(/* webpackChunkName: "about" */ './views/usuario/Usuarios.vue')
        },
        {
          path: 'banner',
          name: 'Banners',
          meta: 'Banners',
          component: () => import(/* webpackChunkName: "about" */ './views/banner/Banner.vue')
        },
        {
          path: 'evento',
          name: 'Eventos',
          meta: 'Eventos',
          component: () => import(/* webpackChunkName: "about" */ './views/evento/Evento.vue')
        },{
          path: 'evento/cadastro',
          name: 'EventosCadastro',
          meta: 'Eventos Cadastro',
          component: () => import(/* webpackChunkName: "about" */ './views/evento/EventoCadastro.vue')
        },
        {
          path: 'quem_somos',
          name: 'QuemSomos',
          meta: 'Quem Somos',
          component: () => import(/* webpackChunkName: "about" */ './views/quem_somos/QuemSomos.vue')
        },
        {
          path: 'sistema',
          name: 'Sistema',
          meta: 'Sistema',
          component: () => import(/* webpackChunkName: "about" */ './views/sistema/Sistema.vue')
        },
        {
          path: 'consultoria',
          name: 'Consultoria',
          meta: 'Consultoria',
          component: () => import(/* webpackChunkName: "about" */ './views/consultoria/Consultoria.vue')
        },
        {
          path: 'cartela',
          name: 'Cartela',
          meta: 'Cartela',
          component: () => import(/* webpackChunkName: "about" */ './views/cartelas/Cartela.vue')
        },
        {
          path: 'cartela/cadastro',
          name: 'CartelaCadastro',
          meta: 'Cartela Cadastro',
          component: () => import(/* webpackChunkName: "about" */ './views/cartelas/CartelaCadastro.vue')
        },
        {
          path: 'cliente',
          name: 'Cliente',
          meta: 'Cliente',
          component: () => import(/* webpackChunkName: "about" */ './views/clientes/Cliente.vue')
        },
        {
          path: 'cliente/cadastro',
          name: 'ClienteCadastro',
          meta: 'Cliente Cadastro',
          component: () => import(/* webpackChunkName: "about" */ './views/clientes/ClienteCadastro.vue')
        },
        {
          path: 'pedidos',
          name: 'Pedidos',
          meta: 'Pedido',
          component: () => import(/* webpackChunkName: "about" */ './views/pedidos/Pedidos.vue')
        },
        {
          path: 'pedidos/cadastro',
          name: 'PedidosCadastro',
          meta: 'Pedido Cadastro',
          component: () => import(/* webpackChunkName: "about" */ './views/pedidos/PedidoCadastro.vue')
        },

        // ACERTOS/APURAÇÃO
        {
          path: 'administrador',
          name: 'Administrador',
          meta: 'Administrador',
          component: () => import(/* webpackChunkName: "about" */ './views/acerto_apuracoes/administradores/Administradores.vue')
        },
        {
          path: 'licencaApuracao',
          name: 'LicencaApuracao',
          meta: 'Licenças de Apuração',
          component: () => import(/* webpackChunkName: "about" */ './views/acerto_apuracoes/licencas_apuracao/LicencaApuracao.vue')
        },
        {
          path: 'sorteio',
          name: 'Sorteio',
          meta: 'Sorteio',
          component: () => import(/* webpackChunkName: "about" */ './views/acerto_apuracoes/sorteios/Sorteio.vue')
        },
        {
          path: 'resultado',
          name: 'Resultado',
          meta: 'Resultado',
          component: () => import(/* webpackChunkName: "about" */ './views/acerto_apuracoes/ganhador/Ganhador.vue')
        },
        {
          path: 'resultado/cadastro',
          name: 'ResultadoCadastro',
          meta: 'Cadastrar Resultado',
          component: () => import(/* webpackChunkName: "about" */ './views/acerto_apuracoes/ganhador/GanhadorCadastro.vue')
        }
      ]
    },
    // 404
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "about" */ './views/404.vue')
    }
  ]
})
