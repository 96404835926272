/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export default class Sistema {

  constructor () {
    this.sistema_id = uuidV4();
    this.descricao = ''
    this.titulo= ''
    this.link= ''
    this.ordem= 0
    this.imagens= [];
    this.urls_imagem= [];
  }
}
