<template>
  <div v-if="textarea">
    <v-textarea :name=name
                :id="id"
                :readonly="disableField"
                dense
                :class=classes
                :rows=rows
                auto-grow
                outlined
                :value="value"
                @input="updateValue"
                :label=label>
    </v-textarea>
  </div>
  <div v-else-if="buildMask">
    <v-text-field :name=name
                  :id="id"
                  dense
                  outlined
                  :label=label
                  :type=pInputType
                  :class=classes
                  :readonly="disableField"
                  :rules=rulesValidation
                  :hint=hint
                  :counter=counter
                  v-model="updateValueMask"
                  :maxlength=maxlength
                  :hide-details=hideDetails
                  v-mask=buildMask>
    </v-text-field>
  </div>
  <div v-else>
    <v-text-field :name=name
                  :id="id"
                  dense
                  outlined
                  :label=label
                  :type=type
                  :value="value"
                  :class=classes
                  :readonly="disableField"
                  :disabled="disabled"
                  :rules=rulesValidation
                  :hint=hint
                  :counter=counter
                  :hide-details=hideDetails
                  :maxlength=maxlength
                  @input="updateValue">
    </v-text-field>
  </div>

</template>

<script>
/*eslint-disable*/
import validator from './commons/validator'
import {mapGetters} from 'vuex'
import {mask} from 'vue-the-mask'
import {removeMascaraCampoAlfaNumerico, removeMascaraCampoNumerico} from './commons/misc'

export default {
  name: 'pText',
  mixins: [validator],
  props: {
    maxlength: {
      type: String
    },
    label: {
      default: ''
    },
    value: {
      type: String | Number
    },
    name: {
      type: String
    },
    id: {
      type: String
    },
    addclass: {
      type: String,
      default: ''
    },
    validation: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    hint: {
      type: String,
      default: ''
    },
    textarea: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    counter: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rows: {
      type: String,
      default: '2'
    }
  },
  data: () => ({
    rulesValidation: [],
    pInputType: null,
    classes: '',
    masked_value: '',
    convertTo: 'U', // U = Upper, L = Lower, N = Nada
    /* eslint-disable */
    mascaraPlaca: {
      mask: 'ZZZ-ZZZZ',
      tokens: {
        'Z': {pattern: /[0-9a-zA-Z]/, transform: v => v.toLocaleUpperCase()},
      }
    }
    /* eslint-enable */
  }),
  directives: {mask},
  methods: {
    updateValue(valor) {
      /* eslint-disable no-console */
      let _valor = this.convertTo === 'U' ? valor.toUpperCase() : this.convertTo === 'L' ? valor.toLowerCase() : valor
      _valor = _valor.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      this.$emit('input', _valor)
      /* eslint-enable no-console */
    }
  },
  computed: {
    buildMask() {
      if (this.validation.indexOf('fone') >= 0) {
        return ['(##)#####-####', '(##)####-####']
      } else if (this.validation.indexOf('cnpj_cpf') >= 0) {
        return ['###.###.###-##', '##.###.###/####-##']
      } else if (this.validation.indexOf('cpf') >= 0) {
        return ['###.###.###-##']
      } else if (this.validation.indexOf('cnpj') >= 0) {
        return ['##.###.###/####-##']
      } else if (this.validation.indexOf('cfop') >= 0) {
        return ['#.###']
      } else if (this.validation.indexOf('cep') >= 0) {
        return ['##.###-###']
      } else if (this.validation.indexOf('rg') >= 0) {
        return ['XX-##.###.###']
      } else if (this.validation.indexOf('hora') >= 0) {
        return ['##:##']
      } else if (this.validation.indexOf('placa') >= 0) {
        return this.mascaraPlaca
      }
      return ''
    },
    updateValueMask: {
      set: function(val) {
        if (this.validation.indexOf('placa') >= 0) {
          this.$emit('input', removeMascaraCampoAlfaNumerico(val))
        } else {
          this.$emit('input', removeMascaraCampoNumerico(val))
        }
      },
      get: function() {
        return this.masked_value
      }
    }
  },
  created() {
    /* eslint-disable no-console */
    this.classes = this.addclass
    this.masked_value = this.value

    if (this.validation !== '') {
      this.buildRules(this)
    }

    // checando se a class requerido foi adicionado no objeto, se não tiver vou adicionar a classe padrão
    if (this.classes.indexOf('requerido2') === -1) {
      this.classes += ' padrao'
    }

    if (this.textarea) {
      this.classes = 'vtextarea padraoTextArea' // aqui depois se tiver a necessidade do required eu quebro o padraoTextArea
    }
    /* eslint-enable no-console */
  },
  watch: {
    disabled: function() {
      this.classes = this.disabled ? 'desabilitado' : this.addclass
    },
    addclass: function() {
      if (this.addclass !== null && this.addclass !== '') {
        this.classes = this.addclass
      }
    }
  }
}
</script>

<style lang="sass">
@import "./commons/p.scss"
</style>
