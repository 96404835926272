/* eslint-disable */
import {lista, processar, autentica, pegaLista, setarToken, pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {ResultadoSCH, getBaseSCH} from "../../search/ResultadoSCH"

import {EventBus} from '../../helpers/event-bus'
import _ from 'lodash'
import Resultado from '../../models/resultado'

const url = '/resultado'

const state = {
  all: [],
  resultado: new Resultado(),
  filtro: new Filtro()
}

const getters = {
  listaResultados: (state) => {
    return state.all
  },
  pegaResultado: (state) => {
    return state.resultado !== null ? state.resultado : new Resultado()
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.addSearch(['nome', 'email'],
       '%'+search+'%',
        'ilike'
      )
  },
  async getItem({commit}, {id, onSucesso, onErro}) {

    try {
      var filtro = getBaseSCH('premio_id', id, '=');
      const list = (await pegaDados(url + '/get', filtro)).data;
      commit('SET_CLIENTE', list.data[0]);
      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }

  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', ResultadoSCH(perPage))
  },
  setResultado({commit}, resultado) {
     commit('SET_CLIENTE', resultado)
  },
  async setAllResultados({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_GANHADORES', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  async processarResultado({commit}, {resultado, acao,onSucesso, onErro}) {
    try {
      // console.log('Prcessar: ', resultado);
      let res = await processar(url + (acao === 'remover' ? '/delete' : '/commit') , resultado, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      console.log(error.response);
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
}

const mutations = {
  SET_CLIENTE(state, resultado) {
    state.resultado = resultado
  },
  SET_GANHADORES(state, resultados) {
    state.all = resultados
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
