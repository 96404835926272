/* eslint-disable */
import axios from 'axios'

export const API = axios.create({
  // withCredentials: false,
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    UserId: sessionStorage.getItem('funcionario_id')
  },
  baseURL: process.env.VUE_APP_API_URL,
  BASE_URL: '/'
})

export function setToken(token,userID) {
  // API.defaults.headers.common.Authorization = `Bearer ${token}`

  sessionStorage.setItem('token', token);
  API.defaults.headers.Authorization = `Bearer ${token}`;
  API.defaults.headers.UserId = userID
}
