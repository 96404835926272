<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-dialog style="z-index: 1000" v-model="Mostrar" :width="width" persistent :fullscreen="fullscreen" :hide-overlay="overlay">
      <v-card>
        <v-card-title class="headline" primary-title id="modalhead"
                      style="background: #c16800; color: white; width: 100%;">
          {{getTitulo()}}
        </v-card-title>
        <v-card-text >
          <v-container >
            <div>
              <slot></slot>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
  export default {
    props: {
      titulo: {
        default: ''
      },
      modal: {
        default: false
      },
      fullscreen: {
        default: false
      },
      dragable: {
        default: false
      },
      width: {
        default: '50%'
      }
    },
    name: "ProModalCadastro",

    data() {
      return {
        overlay: false,
        margem_left: '25',
        classe: '',
      }
    },
    methods: {
      validarCampo(campo) {
        if (campo !== undefined && campo !== null && campo !== '') {
          return true
        } else {
          return false
        }
      },
      getTitulo() {
        if (this.validarCampo(this.titulo)) {
          return this.titulo
        } else {
          return this.$router.currentRoute.meta
        }
      },
    },
    computed: {
      Mostrar: {
        get() {
          return this.modal
        },
        set() {
          return this.Mostrar
        }
      },
    },
    watch: {
      modal: function () {
        if (this.modal) {
          var _this = this;
          var inter = setInterval(function () {
            var mywidth = document.getElementById('drg');
            if (mywidth !== null) {
              var width = window.screen.width;
              var w = mywidth.clientWidth;
              var res = width - w;
              res = res / 2;
              res = (res * 100) / width;
              _this.margem_left = parseInt(res)
            }
            clearInterval(inter)
          }, 500)
        }
      }
    }
  }
</script>

<style scoped>
</style>
