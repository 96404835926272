/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export default class QuemSomos {

  constructor () {
    this.quem_somos_id = uuidV4();
    this.texto = ''
    this.link = ''
    this.imagens= [];
    this.urls_imagem= [];
  }
}
