/* eslint-disable */
import {mask} from 'vue-the-mask'
import router from '../router'
export default {
  directives: {mask},
  data() {
    return {
      rules: {
        required: value => !!value || 'Este campo é obrigatório.',
      },
      listaItens: [],
      erros: [],
      estilo: null,
      dialog_loading: null,
      informacao: null,
      value: null,
      precisao: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
        allowBlank: false,
        min: Number.MIN_SAFE_INTEGER,
        max: Number.MAX_SAFE_INTEGER
      },
      dialog: {
        show: false,
        title: '',
        message: ''
      },
      errorMessages: '',
      formHasErrors: false,
      show_progress: false,
      info_alerta: '',
      cor_alerta: 'primary',
      show_alerta: false,
      customToolbar: [
        [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
        ['bold', 'italic'],
        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'color': [] }],
        ['link', 'formula'],
        ['clean'],
      ],
    }
  },
  methods: {
    removerMasK(str) {
      return this.validarCampo(str) ? str.replace(/[^0-9a-zA-Z]+/g, '') : ''
    },
    maskCpfCnpj(srt) {
      if (this.validarCampo(srt)) {
        if (srt.length <= 11) {
          return srt.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4')
        } else {
          return srt.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5')
        }
      }
    },
    retira_acentos(str)
    {

      var com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ!"

      var sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr "
      var novastr="";
      for(var i=0; i<str.length; i++) {
        var troca=false;
        for (var a=0; a<com_acento.length; a++) {
          if (str.substr(i,1)===com_acento.substr(a,1)) {
            novastr+=sem_acento.substr(a,1);
            troca=true;
            break;
          }
        }
        if (troca===false) {
          novastr+=str.substr(i,1);
        }
      }
      return novastr;
    },
    mascaraValor(valor, decimais, comPrefixo) {
      const numDecimais = (decimais !== null && decimais !== undefined) ? decimais : 2;
      if (valor !== undefined && valor !== null) {
        if (comPrefixo !== null && comPrefixo !== undefined && comPrefixo) {
          return Number(valor).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: numDecimais
          })
        } else {
          return Number(valor).toLocaleString('pt-br', {minimumFractionDigits: numDecimais})
        }
      }
      return ''
    },
    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    itens(item) {
      this.listaItens = item
    },
    closeDialog(dialog) {
      dialog.close()
    },
    toDecimal(v) {
      try {
        let ret = 0;
        if (this.validarCampo(v)) {
          ret = parseFloat(parseFloat(v).toFixed(2))
        }
        return ret
      } catch (e) {
        console.log(e)
      }
    },
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      var r =  parseInt(result[1], 16)
      var g =  parseInt(result[2], 16)
      var b =  parseInt(result[3], 16)
      return r + ', ' + g + ', ' + b + ', 0.5'
    },
    zeroEsquerda(value, totalWidth, paddingChar) {
      var length = totalWidth - value.toString().length + 1
      return Array(length).join(paddingChar || '0') + value
    },
    getDataAtual() {
      var d = this.zeroEsquerda(new Date().getDate(), 2)
      var m = this.zeroEsquerda(new Date().getMonth() + 1, 2)
      var a = new Date().getFullYear()
      return a + '-' + m + '-' + d
    },
    getHoraAtual() {
      var h = this.zeroEsquerda(new Date().getHours(), 2)
      var m = this.zeroEsquerda(new Date().getMinutes(), 2)
      return h + ':' + m
    },
    inverterData(newData) {
      var xvals = '';
      if (this.validarCampo(newData)) {
        xvals = newData.split('-');
        return xvals[2] + '/' + xvals[1] + '/' + xvals[0]
      } else {
        return xvals
      }
    },

    retorno (item, termo, retorno1, retorno2) {
      return item === termo ? retorno1 : retorno2
    },
    validarCampo(campo) {
      if (campo !== undefined && campo !== null && campo !== '') {
        return true
      } else {
        return false
      }
    },
    removerMascara (str) {
      if (this.validarCampo(str)) {
        return str.replace(/[^0-9]+/g, '')
      } else {
        return ''
      }
    },
    getValueByKey (item,key) {
      if (item[key] === undefined) {
        return item
      } else {
        return item[key]
      }
    },
    focusInput(id) {
      var loadTime = 10
      window.onload = function () {
        loadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;
      }
      var interval = setInterval(function () {
        var muniImpt = document.getElementById(id)
        if (muniImpt !== null) {
          muniImpt.focus()
        }

        clearInterval(interval)
      }, loadTime)
    },
    // ALERTA
    open_alerta(text, cor) {
      this.info_alerta = text
      this.cor_alerta = cor
      this.show_alerta = true
      var _this = this
      var intervalo = setInterval(function () {
        _this.show_alerta= false
        clearInterval(intervalo)
      }, 5000)
    },
    fechar_alerta() {
      this.show_alerta = false
    },
    ArrayDiff(array_1,array_2){
      Array.prototype.diff = function(a) {
        return this.filter(function(i) {return a.indexOf(i) < 0;});
      };
      return array_1.diff( array_2 )
    },
    ArrayGetKeys(array, key){
      var a = []
      for (var i = 0; i < array.length; i++) {
        a.push(array[i][key])
      }
      return a
    },
    setRequerido(str) {
      if (!this.validarCampo(str)) {
        return 'red'
      } else {
        return 'inherit'
      }
    },
    voltar(path) {
      router.push({name: path}).catch(err => {
      })
    },
    updateTexto(txt,valor){
      return this.validarCampo(txt) ? txt : valor
    },
    retornoValido(campo,key, retorno) {
      if (this.validarCampo(campo)) {
        return this.validarCampo(campo[key]) ? campo[key] : retorno
      } else {
        return ''
      }
    },
  },
}
