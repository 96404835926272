/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export default class LicencaApuracao {

  constructor () {
    this.licenca_apuracao_id = uuidV4();
    this.administrador_id = '';
    this.dt_vencimento = ''
    this.observacoes = ''
    this.bloqueada = false
  }
}