/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export default class Resultado {

  constructor () {
    this.premio_id = uuidV4();
    this.sorteio_id = '';
    this.premio = '';
    this.bolas_sorteadas = '';
    this.ganhadores = [];
  }
}
