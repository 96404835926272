/* eslint-disable */
import {lista, processar, pegaLista, pegaDados, nao_autorizado} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {QuemSomosSCH, getBaseSCH} from "../../search/QuemSomosSCH"
import {EventBus} from '../../helpers/event-bus'
import QuemSomos from '../../models/quem_somos'

const url = '/quem_somos';

const state = {
  all: [],
  quem_somos: new QuemSomos(),
  filtro: new Filtro()
};
const getters = {
  listaQuemSomos: (state) => {
    return state.all !== undefined ? state.all : []
  },
  pegaQuemSomos: (state) => {
    return state.quem_somos
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};


const actions = {
  setQuemSomos({commit}, quem_somos) {
    commit('SET_QUEMSOMO', quem_somos)
  },
  // searchItem({commit}, search) {
  //   state.filtro.filters.addFilter('descricao' ,
  //     "%"+search+"%",
  //     'like'
  //   )
  // },
  async getItem({commit}, id) {
    var filtro = getBaseSCH('quem_somos_id', id, '=');
    const list = (await pegaLista(url, filtro)).data;
    commit('SET_QUEMSOMOS', list.data[0]);
    EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', QuemSomosSCH(perPage))
  },

  async setAllQuemSomos({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_QUEMSOMOS', list);
      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      // this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }

  },
  async processarQuemSomos({commit}, {quem_somos,acao,onSucesso, onErro}) {
    try {
      console.log(quem_somos);
      let res = await processar(url + (acao === 'remover' ? '/delete' : '/commit') , quem_somos, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data);
    }
  },

};

const mutations = {
  SET_QUEMSOMOS(state, quem_somoss) {
    state.all = quem_somoss
  },
  SET_QUEMSOMO(state, quem_somos) {
    state.quem_somos = quem_somos
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

