<template>
  <v-chip :color="bloqueado === valor ? 'red' : 'green'" dark x-small>
    {{bloqueado === valor ? 'NÂO' : 'SIM'}}
  </v-chip>
</template>

<script>
/* eslint-disable */
  export default {
    name: "IsBlocked",
    props: ['bloqueado', 'valor']
  }
</script>
