/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import geral from './modules/geral'
import banner from './modules/banners'
import evento from './modules/eventos'
import usuario from './modules/usuarios'
import sistema from './modules/sistemas'
import consultoria from './modules/consultorias'
import cartela from './modules/cartelas'
import cliente from './modules/clientes'
import pedido from './modules/pedidos'
import resultado from './modules/resultados'
import quem_somos from './modules/quem_somos'
import administrador from './modules/administrador'
import licenca_apuracao from './modules/licenca_apuracao'
import sorteio from './modules/sorteio'
import token from "./modules/token";


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    token,
    geral,
    banner,
    evento,
    usuario,
    sistema,
    resultado,
    consultoria,
    cartela,
    cliente,
    pedido,
    quem_somos,
    administrador,
    licenca_apuracao,
    sorteio
  }
})
