/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export default class ClientePedido {

  constructor (clienteId) {
    this.cliente_pedido_id = uuidV4();
    this.cliente_id = clienteId
    this.quantidade = ''
    this.entidade = ''
    this.titulo_evento = ''
    this.dt_evento = ''
    this.hora_evento = ''
    this.local = ''
    this.telefone = ''
    this.descricao = ''
    this.descricao_premio = ''
    this.vl_cartela = 0
    this.imagens = []
    this.urls_imagem = []
  }
}
