/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export default class Cliente {

  constructor () {
    this.cliente_id = uuidV4();
    this.nome = ''
    this.email = ''
    this.senha = ''
    this.cpf_cnpj = ''
    this.cep = ''
    this.endereco = ''
    this.bairro = ''
    this.municipio = ''
    this.uf = ''
    this.email = ''
    this.clientes_pedidos = []
  }
}
