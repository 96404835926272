/* eslint-disable */
import {pegaDados} from '../../services/GenericAPI'
import {Filtro, Where} from "../../models/filtro/filtro";
import {getBaseSCH, PedidoSCH} from "../../search/PedidoSCH"
import ClientePedido from "../../models/clientes_pedidos";

const url = '/pedido'

const state = {
  all: [],
  pedido: new ClientePedido(''),
  filtro: new Filtro()
}

const getters = {
  listaPedidos: (state) => {
    return state.all
  },
  pegaPedido: (state) => {
    return state.pedido
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.addSearch(['nome', 'entidade','status','modelo','cpf_cnpj'],
       '%'+search+'%',
        'ilike'
      )
  },
  async getItem({commit}, {id, onSucesso, onErro}) {

    try {
      var filtro = getBaseSCH('cliente_pedido_id', id, '=');
      const list = (await pegaDados(url + '/get_pedido', filtro)).data;
      commit('SET_PEDIDO', list.data[0]);
      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }

  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', PedidoSCH(perPage))
  },
  setPedido({commit}, pedido) {
     commit('SET_PEDIDO',pedido)
  },
  async setListaPedidos({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_PEDIDOS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

}

const mutations = {
  SET_PEDIDO(state, pedido) {
    state.pedido = pedido
  },
  SET_PEDIDOS(state, listaPedidos) {
    state.all = listaPedidos
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
