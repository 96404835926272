/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const getBaseSCH = (campo, valor, condicao) => {
  var filtro = new Filtro();
  filtro.perPage = 1;
  filtro.fields = ['*'];
  filtro.filters.addFilter(campo, valor, condicao);
  return filtro
};

export const ResultadoSCH = (perPage) => {
  var filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['premio', 'apuracao_tv.premios.sorteio_id', 'bolas_sorteadas', 'apuracao_tv.ganhadores.cidade', 'entidade','dt_sorteio'];
  filtro.join.addLeft('acerto.sorteios', 'acerto.sorteios.sorteio_id', 'apuracao_tv.premios.sorteio_id')
  filtro.join.addLeft('apuracao_tv.ganhadores', 'apuracao_tv.ganhadores.premio_id', 'apuracao_tv.premios.premio_id')
  return filtro

};
