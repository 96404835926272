/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const  getBaseSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro();
  filtro.perPage = 1;
  filtro.filters.addFilter(campo,valor,condicao);
  return filtro
};


export const SorteioSCH = (perPage)=> {
    var filtro = new Filtro();
    filtro.perPage = perPage;
    filtro.fields = ['acerto.sorteios.*', 'apuracao_tv.faixa_venda_online.*', 'acerto.administradores.nome as administrador'];
    filtro.join.addLeft('acerto.administradores', 'acerto.sorteios.administrador_id', 'acerto.administradores.administrador_id')
    filtro.join.addLeft('apuracao_tv.faixa_venda_online', 'acerto.sorteios.sorteio_id', 'apuracao_tv.faixa_venda_online.sorteio_id')
  return filtro

};
