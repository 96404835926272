/* eslint-disable */
import {processar, pegaDados} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {LicencaApuracaoSCH, getBaseSCH} from "../../search/LicencaApuracaoSCH"
import LicencaApuracao from '../../models/licenca_apuracao';

const url = '/licenca_apuracao';

const state = {
  all: [],
  licencaApuracao: new LicencaApuracao(),
  filtro: new Filtro()
};
const getters = {
  listaLicencasApuracao: (state) => {
    return state.all !== undefined ? state.all : []
  },
  pegaLicencaApuracao: (state) => {
    return state.licencaApuracao
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};


const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.addSearch(['acerto.administradores.nome', 'dt_vencimento'],
       '%'+search+'%',
        'ilike'
      )
  },

  setLicencaApuracao({commit}, licencaApuracao) {
    commit('SET_EVENTO', licencaApuracao)
  },
  async getItem({commit}, {id, onSucesso, onErro}) {
    try {
      var filtro = getBaseSCH('licenca_apuracao_id', id, '=');
      const list = (await pegaDados(url + '/get', filtro)).data;
      commit('SET_EVENTO', list.data[0]);

      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', LicencaApuracaoSCH(perPage))
  },

  async setAllLicencasApuracao({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_EVENTOS', list);
      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      onErro()
    }

  },
  async processarLicencaApuracao({commit}, {licencaApuracao, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url + (acao === 'remover' ? '/delete' : '/commit') , licencaApuracao, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data);
    }
  },
  async processarOpcao({commit}, {licencaApuracao, path, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url + '/' + path, licencaApuracao, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      console.log('Caiu no Catch')
      console.log(error)
      onErro()
    }
  },
};

const mutations = {
  SET_EVENTOS(state, licencaApuracaoes) {
    state.all = licencaApuracaoes
  },
  SET_EVENTO(state, licencaApuracao) {
    state.licencaApuracao = licencaApuracao
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
