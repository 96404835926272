/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
import FaixaVendaOnline from './faixa_venda_online';

export default class Sorteio {
  constructor () {
    this.sorteio_id = uuidV4()
    this.administrador_id = ''
    this.entidade = ''
    this.cidade = ''
    this.dt_sorteio = ''
    this.url_live = ''
    this.dt_promocao = ''
    this.dt_licenca_ate = ''
    this.tp_sorteio = 'P'
    this.tem_cartela_extra = false
    this.vender_online = false

    this.total_cartelas_normal = 0
    this.vl_cartelas_normal = 0
    this.vl_cartela_normal_promocao = 0
    this.vl_comissao_cartela_normal = 0
    this.vl_comissao_normal_promocao = 0

    this.total_cartelas_extra = 0
    this.vl_cartelas_extra = 0
    this.vl_cartela_extra_promocao = 0
    this.vl_comissao_cartela_extra = 0
    this.vl_comissao_extra_promocao = 0
    this.tabela_bolas = '75b'

    this.faixa_venda_online = new FaixaVendaOnline(uuidV4(), this.sorteio_id)
  }
}
